import React, { useState, useEffect } from "react";
import { get, patch, post } from "../../utils/axiosInstance";
import Logo from "../../assets/Logo.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  SelectedVendorId,
  setBookedDates,
  setLogin,
  setSelectedVendor,
} from "../../state";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StarRatings from "react-star-ratings";
import { Confirmationmodal } from "../Modals/Confirmationmodal";
import PopupModal from "../Modals/Popupmodal";

const Vendors = ({ switchComponent, goToNextStep }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const Token = useSelector((state) => state.token);
  const [apiData, setApiData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState(null);




  useEffect(() => {
    get(`/vendor`, {}, {}, Token)
      .then((res) => {
        if ("response" in res) {
          const response = res.response?.data?.vendors;

          dispatch(setBookedDates([]));
          setApiData(response);
        }
      })
      .catch((err) => {
        console.error("Error fetching vendors:", err);
      });
  }, []);

  const selectedVendor = useSelector((state) => state.selectedVendor);

  const handleButtonClick = (label, item) => {
    if (
      user?.user_insurance?.status === "rejected" &&
      user?.user_license?.status === "rejected"
    ) {
      toast("License Rejected", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        type: "error",
      });
    } else {
      dispatch(setSelectedVendor(item));
    }
  };

  const [abc, setAbc] = useState({ label: "", item: null });

  const postSelectedVendorId = (item) => {
    patch(
      `/user/update`,
      {
        user_selected_vendor_id: item,
        vendor_id: item,
      },
      {},
      Token
    )
      .then((res) => {
        dispatch(
          setLogin({
            user: res.response.data.user,
            token: res.response.data.user?.token,
            user_type: res.response.data.user?.user_type,
            app_vendor_id: res.response.data.user?.app_vendor_id,
            user_selected_vendor_id: null,
          })
        );
      })
      .catch((err) => {
        console.error("Error posting user selected vendor ID:", err);
      });
  };

  const handleYesButtonClick = () => {
    //
    dispatch(setSelectedVendor(null));
    //
    postSelectedVendorId(abc?.item?.user?.vendor_id);
    handleButtonClick(abc?.item);
    switchComponent(abc?.label);
    dispatch(setSelectedVendor(abc?.item));
    setShowModal(false);
    goToNextStep();
  };

  const handleNoButtonClick = () => {
    handleButtonClick(abc?.item);
    switchComponent(abc?.label);
    dispatch(setSelectedVendor(abc?.item));
    setShowModal(false);
    goToNextStep();
  };

  return (
    <div className="bg-white rounded-[10px] flex flex-col justify-center items-center w-full h-[87vh] md:h-[670px] p-4">
      <div className="custom-scrollbar w-full h-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 place-items-center">
          {apiData &&
            Array.isArray(apiData) &&
            apiData.map((item, ind) => (
              <div
                key={ind}
                className="border border-[#E2E2E2] rounded-[20px] shadow-md max-w-[280px] w-full bg-white"
              >
                <div
                  onClick={() => {
                    setShowModal(true);
                    setAbc({ label: "Searchaddress", item });
                  }}
                  className="flex flex-col shadow-lg cursor-pointer m-3 relative group transition-transform duration-300 hover:scale-105"
                >
                  {item?.logo ? (
                    <img
                      src={item?.logo}
                      className="w-full max-h-[130px] rounded-t-[20px] object-cover"
                    />
                  ) : (
                    <div className="bg-gray-100 w-full h-[130px] flex justify-center items-center rounded-t-[20px]">
                      <img src={Logo} className="w-[90%] max-h-[130px]" />
                    </div>
                  )}

                  <div className="p-4 text-center">
                    <p className="text-black text-lg font-bold">{item?.title}</p>
                    <p className="text-gray-600 text-sm line-clamp-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.description ?? "",
                        }}
                      ></div>
                    </p>
                  </div>

                  <div className="flex justify-center items-center gap-1 pb-4">
                    <StarRatings
                      rating={item.average_ratings}
                      isSelectable={false}
                      starDimension="18px"
                      starSpacing={"-1px"}
                      numberOfStars={1}
                      isAggregateRating={true}
                      starRatedColor="rgb(255, 193, 7)"
                    />
                    <p className="text-gray-700 text-sm">
                      {item.average_ratings?.toFixed(1)}/5
                    </p>
                  </div>
                </div>

                <button
                  className="w-full p-3 rounded-b-[20px] bg-blue-600 text-white font-medium transition-all duration-300 hover:bg-blue-700"
                  onClick={() => {
                    setShowModal(true);
                    setAbc({ label: "VendorDetails", item });
                    dispatch(setSelectedVendor(item));
                    switchComponent("VendorDetails", item);
                  }}
                >
                  Vendor Details
                </button>
              </div>
            ))}
        </div>
      </div>

      <Confirmationmodal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedVendorId={selectedVendorId}
        onYesButtonClick={handleYesButtonClick}
        onNoButtonClick={handleNoButtonClick}
        name={abc?.item?.title}
      />

      <ToastContainer limit={1} />
    </div>
  );
};

export default Vendors;
