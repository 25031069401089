import React, { useState, useEffect } from "react";
import { get, patch } from "../../utils/axiosInstance";
import { useSelector, useDispatch } from "react-redux";
import VendorMap from "../VendorMap";
import { VendorDiscountModal } from "../Modals/Vendorsdiscountmodal";
import Spinner from "../Spinner";
import Email from "../../assets/Png/Email.png";
import Call from "../../assets/Png/Call.png";
import Tags from "../../assets/Png/Tags.png";
import Discount from "../../assets/Png/Discount.png";
import Cover from "../../assets/Png/cover.jpg";
import Logo from "../../assets/Png/logo.png";

import Toast from "../Toast";
import moment from "moment";
import { setLogin, setSelectedVendor, setUseJourneyVendor } from "../../state";

import StarRatings from "react-star-ratings";
import { Confirmationmodal } from "../Modals/Confirmationmodal";

const DefaultVendorDetails = ({ onBack, vendor }) => {
  const dispatch = useDispatch();
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const [apiData, setApiData] = useState({});

  const [googleApiData, googleSetApiData] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const Token = useSelector((state) => state.token);
  const [showFullText, setShowFullText] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const [showFullComments, setshowFullComments] = useState("");
  const [starDimension, setStarDimension] = useState(
    window.innerWidth >= 768 ? "20px" : "8px"
  );

  useEffect(() => {
    const handleResize = () => {
      setStarDimension(window.innerWidth >= 768 ? "20px" : "8px");
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBackButtonClick = () => {
    dispatch(setSelectedVendor(null));
  };

  const openImageModal = (apiData) => {
    setSelectedImage(apiData);
  };

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  useEffect(() => {
    get(`/vendor/rating/${vendor?.place_id}`, {}, {}, Token).then(
      (res) => {
        if (res.response.data !== null) {
          googleSetApiData(res.response.data);
        } else {
          googleSetApiData(null);
        }
      }
    );
  }, []);

  useEffect(() => {
    setLoader(true);
    get(`/vendor/${vendor?.id}`, {}, {}, Token)
      .then((res) => {
        setLoader(false);
        if ("response" in res) {
          const response = res.response?.data?.vendor;
          if (
            response?.latitude !== undefined &&
            response?.longitude !== undefined
          ) {
            setSelectedLatLng({
              lat: parseFloat(response?.latitude),
              lng: parseFloat(response?.longitude),
            });
          }
          setApiData(response);
        }
      })
      .catch((err) => {
        Toast({ message: "Response Error Show", isError: true });
      });
  }, []);

  const [reviewsToShow, setReviewsToShow] = useState(4);

  const loadMoreReviews = () => {
    setReviewsToShow((prevCount) => prevCount + 4);
  };

  const [visibleItems, setVisibleItems] = useState(3);

  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3);
  };

  const renderTimestamp = (timestamp) => {
    const currentDate = moment();
    const date = moment(timestamp);

    if (currentDate.diff(date, "days") > 6) {
      return <p>{date.format("MMM DD, YYYY")}</p>;
    } else {
      const formattedDate = date.fromNow();
      return <p>{formattedDate}</p>;
    }
  };

  const colors = [
    "#0075FF",
    "#F1BE36",
    "#9215D8",
    "#9F0000",
    "#000000",
    "#307617",
  ];

  function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)];
  }


  const [abc, setAbc] = useState({ label: "", item: null });

  const postSelectedVendorId = (item) => {
    patch(
      `/user/update`,
      {
        user_selected_vendor_id: item,
        vendor_id: item,
      },
      {},
      Token
    )
      .then((res) => {
        //      dispatch(setLogin({
        //   user:    res.response.data.user,
        //   token:    res.response.data.user?.token,
        //   user_type:    res.response.data.user?.user_type,
        //   app_vendor_id:    res.response.data.user?.app_vendor_id,
        //   user_selected_vendor_id: null,
        // }));
        //   })
        //  dispatch(
        //   setLogin({
        //     user_selected_vendor_id: res.response.data.user.default_vendor.id,
        //   })
        // );


        dispatch(
          setLogin({
            user: res.response.data.user,
            token: res.response.data.user?.token,
            user_type: res.response.data.user?.user_type,
            app_vendor_id: res.response.data.user?.app_vendor_id,
            user_selected_vendor_id: res.response.data.user.default_vendor.id,

          })
        );
        dispatch(setSelectedVendor(res.response.data.user.default_vendor));
        dispatch(setUseJourneyVendor(res.response.data.user.default_vendor.id));
      })

      .catch((err) => {
        console.error("Error posting user selected vendor ID:", err);
      });
  };

  const handleNextButtonClick = () => {
    setShowModal(true);
  };

  const handleYesButtonClick = () => {
    //
    dispatch(setSelectedVendor(null));
    //
    postSelectedVendorId(vendor.id);
    // switchComponent("Searchaddress");
    dispatch(setSelectedVendor(vendor));
    setShowModal(false);
    // goToNextStep();
  };

  const handleNoButtonClick = () => {
    dispatch(setSelectedVendor(abc?.item));
    setShowModal(false);
  };

  function getFirstLetters(str) {
    const firstWord = str.split(" ")[0];
    return (
      <div
        className={`rounded-full w-9 h-9 flex justify-center items-center`}
        style={{ backgroundColor: getRandomColor() }}
      >
        <p className="text-white text-center">{firstWord.charAt(0)}</p>
      </div>
    );
  }

  if (loader) {
    return (
      <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
        <Spinner className="spinner" isLoading={loader} />
      </div>
    );
  } else {
    return (
      <div className='bg-[#FFFFFF] rounded-[10px]  flex flex-col justify-center mx-auto items-center 2xl:w-[1000px] xl:w-[700px] lg:w-[400px]] md:w-[500px] sm:w-[400px] w-[300px] md:h-[670px] h-[60vh]'>

        <div className='p-4 w-[90%] custom-scrollbar h-[100%]'>
          <div className="relative">

            <img
              src={apiData?.cover ? apiData?.cover : Cover}
              className="w-full md:h-[300px] h-[200px]"
              alt="sadasasd"
            />
            <div className="absolute top-[100%] xl:left-[9%] lg:left-[15%] md:left-[12%] left-[19%] transform -translate-x-1/2 -translate-y-1/2 bg-white lg:w-[150px] lg:h-[150px] w-[90px] h-[90px] rounded-full shadow-[0_5px_20px_-15px_black]">
              <img
                src={apiData?.logo ? apiData?.logo : Logo}
                className="rounded-full w-full h-full  p-1 z-50"
                alt="sadasasd"
              />
            </div>
          </div>
          <div className="mt-[90px]">
            <p className="text-Textdarkcolor text-[20px] font-bold">
              {apiData?.title}
            </p>
          </div>
          <div>
            <div className="mt-2 flex items-center">
              <img className="w-3 h-3" src={Email} />
              <p className="ml-2 text-Textlitecolor text-[12px]">
                {apiData?.user?.email}{" "}
              </p>
            </div>
            <div className="mt-1 flex items-center">
              <img className="w-3 h-3" src={Call} />
              <p className="ml-2 text-Textlitecolor  text-[12px]">
                {" "}
                {apiData?.user?.phone}{" "}
              </p>
            </div>

            <div className="lg:text-[14px]   text-[10px]  mt-[18px] w-full text-[Textlitecolor]">
              {showFullText ? (
                <div className="">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: apiData?.description ?? "",
                    }}
                  ></div>
                  <button
                    className="text-blue-500 flex cursor-pointer"
                    onClick={toggleReadMore}
                  >
                    {" "}
                    <p className="">
                      <p className=" ">
                        {apiData?.description?.length > 400
                          ? " Show less..."
                          : null}
                      </p>
                    </p>
                  </button>
                </div>
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        apiData?.description?.length > 400
                          ? apiData?.description.substr(0, 400) + " ..."
                          : apiData?.description,
                    }}
                  ></div>
                  <button
                    className="text-blue-500 flex cursor-pointer"
                    onClick={toggleReadMore}
                  >
                    <p className=" ">
                      {apiData?.description?.length > 400 ? "Show more" : null}
                    </p>
                  </button>
                </>
              )}
            </div>
          </div>

          <div class="w-full mx-auto my-4">
            <p className="text-Textdarkcolor text-[20px] font-bold mt-5 h-12 text-xl   flex items-center drop-shadow-lg">
              Available Discounts{" "}
            </p>
            <div class="bg- w-full   flex flex-row custom-scrollbar">
              {apiData &&
                apiData?.discounts &&
                apiData?.discounts.length > 0 ? (
                apiData?.discounts?.map((item) => (
                  <>
                    <div
                      key={item?.id}
                      onClick={() => openImageModal(item)}
                      className="flex cursor-pointer bg-blue-50 p-1    h-24 w-[305px] md:w-[330px] ml-2 rounded-md shrink-0"
                    >
                      <div className="flex-col p-3 w-[100%]  ">
                        <div className="flex ">
                          <img className="md:w-5 md:h-5 w-3 h-3" src={Tags} />
                          <p className="text-Appcolor md:text-[12px] text-[9px] font-semibold md:ml-1 ml-[2px]">
                            {item.name}
                          </p>
                        </div>
                        <span className="text-Textlitecolor w-[75%] line-clamp-2 text-[12px]">
                          {item?.description}
                        </span>
                      </div>
                      <img
                        className=" mt-auto ml-auto mr-2 w-9 h-9"
                        src={Discount}
                      />
                    </div>
                  </>
                ))
              ) : (
                <div className="mt-2 w-full">
                  <p className="text-Textlitecolor">No Discount Available</p>
                </div>
              )}
            </div>
          </div>

          {/*  Available Discounts End */}

          {/* Map Adress Start */}
          <div>
            <p className="text-Textdarkcolor  text-[20px] font-bold my-4">
              Vendor Location
            </p>

            <VendorMap
              containerStyle={{ height: "250px" }}
              stores={apiData.stores}
              selectedLocation={selectedLatLng}
            />
          </div>
          {/* Map Adress End */}

          {/* Ratings & Reviews Start */}
          <p className="text-Textdarkcolor text-[20px] font-bold my-4">
            {googleApiData.rating === 0
              ? "Ratings & Reviews"
              : "Google Ratings & Reviews"}
          </p>
          <div className="mt-1 flex w-[100%]">
            <div className="flex-col w-[20%]">
              <p className="text-center md:text-[20px] text-[14px] font-bold">
                {googleApiData.rating === 0
                  ? apiData?.average_ratings?.toFixed(1)
                  : googleApiData?.rating?.toFixed(1)}
              </p>
              <div className="w-full h-6 flex justify-center">
                <StarRatings
                  rating={
                    googleApiData.rating === 0
                      ? apiData?.average_ratings
                      : googleApiData.rating
                  }
                  starDimension={starDimension}
                  starSpacing={"0px"}
                  isSelectable={false}
                  starRatedColor="rgb(255, 193, 7)"
                />
              </div>

              <p className="text-center text-Textlitecolor md:text-[16px] text-[11px] mt-1">
                {googleApiData.rating === 0
                  ? apiData?.total_ratings + " reviews"
                  : googleApiData.user_ratings_total + " reviews"}
              </p>
            </div>
            {googleApiData.rating === 0 ? (
              <div className="flex-col ml-5 w-full ">
                {apiData?.reviews?.slice(0, visibleItems).map((item, ind) => (
                  <div className=" w-full flex mt-2" key={ind}>
                    <div
                      className={`rounded-[100px] w-9 h-9 flex justify-center items-center`}
                      style={{ backgroundColor: colors[ind] }}
                    >
                      <p className="text-white text-center">
                        {getFirstLetters(item.user.first_name)}
                      </p>
                    </div>
                    <div className="flex-col ml-5  w-full" key={item.id}>
                      <div className="flex justify-between w-full">
                        <div className="flex items-center gap-2">
                          <ul className="  flex items-center w-full">
                            <div>
                              <StarRatings
                                rating={item.ratings}
                                isSelectable={false}
                                starDimension={starDimension}
                                starSpacing={"0px"}
                                isAggregateRating={true}
                                starRatedColor="rgb(255, 193, 7)"
                              />
                            </div>
                          </ul>

                          <p className="md:ml-3 ml-1 md:text-[16px] text-[10px]">
                            {item?.user?.first_name}
                          </p>
                        </div>
                        <p className="md:text-[16px] text-[9px] text-center items-center">
                          {" "}
                          {renderTimestamp(item?.created_at)}{" "}
                        </p>
                        <p className="md:text-[16px] text-[9px] text-center items-center">
                          {" "}
                          {item?.relative_time_description}
                        </p>
                      </div>
                      <div
                        className={`lg:text-[14px] ${item?.comments ? "my-1 pb-2" : "my-2 py-1"
                          } border-gray-100 border-b-[1px] text-[10px] w-full text-[Textlitecolor]`}
                      >
                        {showFullComments === item?.id ? (
                          <div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.comments ?? "",
                              }}
                            ></div>
                          </div>
                        ) : (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  item?.comments?.length > 100
                                    ? item?.comments?.substr(0, 100) + " ..."
                                    : item?.comments,
                              }}
                            ></div>
                            <button
                              className="text-blue-500 flex cursor-pointer"
                              onClick={() => {
                                setshowFullComments(item?.id);
                              }}
                            >
                              <p className="">
                                {item?.comments?.length > 50
                                  ? "Show more"
                                  : null}
                              </p>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                {apiData?.reviews?.length > visibleItems ? (
                  <div className="flex justify-center md:mt-4 mt-2">
                    <button
                      className="text-blue-500 cursor-pointer"
                      onClick={loadMoreItems}
                    >
                      Load More
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center md:mt-4 mt-2">
                    <button
                      className="text-blue-500 cursor-pointer"
                      onClick={() => {
                        setVisibleItems(3);
                      }}
                    >
                      {visibleItems.length > 3 ? "Show Less" : ""}
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex-col ml-5 w-full ">
                {googleApiData?.reviews
                  ?.slice(0, visibleItems)
                  .map((item, ind) => (
                    <div className=" w-full flex mt-2">
                      <div
                        className={`rounded-[100px] w-9 h-9 flex justify-center items-center`}
                        style={{ backgroundColor: 0 }}
                      >
                        <p className="text-white text-center">
                          {getFirstLetters(item?.author_name)}
                        </p>
                      </div>
                      <div className="flex-col ml-2  w-full">
                        <div className="flex justify-between w-full items-center">
                          <div className="flex items-center  ">
                            <ul className="flex items-center">
                              <div>
                                <StarRatings
                                  rating={item.rating}
                                  isSelectable={false}
                                  starDimension={starDimension}
                                  starSpacing={"0px"}
                                  isAggregateRating={true}
                                  starRatedColor="rgb(255, 193, 7)"
                                />
                              </div>
                            </ul>

                            <p className="md:text-[16px] text-[9px] ml-1  md:ml-3">
                              {item?.author_name}
                            </p>
                          </div>
                          <p className="md:text-[16px] text-[9px] text-center items-center">
                            {" "}
                            {item?.relative_time_description}
                          </p>
                        </div>
                        <div
                          className={`lg:text-[14px] ${item?.text ? "my-1 pb-2" : "my-2 py-1"
                            } border-gray-100 border-b-[1px] text-[10px] w-full text-[Textlitecolor]`}
                        >
                          {showFullComments === ind ? (
                            <div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.text ?? "",
                                }}
                              ></div>
                            </div>
                          ) : (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item?.text?.length > 100
                                      ? item?.text?.substr(0, 100) + " ..."
                                      : item?.text,
                                }}
                              ></div>
                              <button
                                className="text-blue-500 flex cursor-pointer"
                                onClick={() => {
                                  setshowFullComments(ind);
                                }}
                              >
                                <p className="">
                                  {item?.text?.length > 50 ? "Show more" : null}
                                </p>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                {googleApiData?.reviews?.length > visibleItems ? (
                  <div className="flex justify-center md:mt-4 mt-2">
                    <button
                      className="text-blue-500  md:text-[16px] text-[12px] cursor-pointer"
                      onClick={loadMoreItems}
                    >
                      Load More
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center md:mt-4 mt-2">
                    <button
                      className="text-blue-500 md:text-[16px] text-[12px] cursor-pointer"
                      onClick={() => {
                        setVisibleItems(3);
                      }}
                    >
                      {visibleItems.length > 3 ? "Show Less" : ""}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* Ratings & Reviews End */}

          <div className="flex justify-between w-[100%] my-4">
            <button
              className="bg-Btncolor text-Textdarkcolor lg:text-[16px] text-[12px] rounded-[10px]  
                            lg:w-[150px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[110px] h-[35px]   "
              onClick={onBack}
            >
              Back
            </button>
            <button
              className={`button lg:w-[150px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[110px] h-[35px]   shadow-[0_5px_40px_-20px_#0075FF] text-white font-normal lg:text-[16px] text-[12px]`}
              onClick={handleNextButtonClick}
            >
              Continue
            </button>
          </div>

          {selectedImage && (
            <VendorDiscountModal
              apiData={selectedImage}
              onClose={() => setSelectedImage(null)}
            />
          )}

        </div>
        <Confirmationmodal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedVendorId={vendor?.id}
          onYesButtonClick={handleYesButtonClick}
          onNoButtonClick={handleNoButtonClick}
          name={vendor?.title}
        />
      </div>
    );
  }
};

export default DefaultVendorDetails;
