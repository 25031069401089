import React from "react";
import Logo from "../../assets/Logo.svg";
import Vehicle from "../../assets/Vehicle.svg";
import Googleplay from "../../assets/Png/Googleplay.png";
import Appstore from "../../assets/Png/Applestore.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidecard = ({
  isFromSearchcart,
  imageData,
  OnVendor,
  Property,
  helpsupport,
  loading = false
}) => {
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const user = useSelector((state) => state.user);

  const logo = selectedVendor?.logo ?? user?.default_vendor?.logo;

  return (
    <div className="w-[90%] bg-white h-[670px] border border-gray-200 rounded-lg">
      <div className="flex flex-col justify-end pt-4 items-center pb-10">
        {
          loading ?
            (
              <div class="flex items-center justify-center lg:w-[220px] w-[137px] h-[210px] bg-gray-300 sm:w-96 dark:bg-gray-500 rounded-full animate-pulse">
                <svg class="w-10 h-10 text-gray-200 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            )
            :
            (
              <div>
                {Property || OnVendor || helpsupport ? (
                  <img
                    className="lg:w-[220px] w-[137px] h-[210px] mx-auto "
                    src={Logo}
                  />
                ) : (
                  <img
                    className="lg:w-[220px] w-[137px] h-[210px] mx-auto rounded-full "
                    src={logo}
                  />
                )}
              </div>
            )
        }
        {isFromSearchcart ? (
          <img
            className="w-[268px] h-[241px] md:mt-[30px] rounded-md shadow-lg "
            src={imageData ? imageData : require('../../assets/lsv_1.png') || logo}
          />
        ) : (
          <img className="w-[268px] h-[241px] md:mt-[10px] " src={require('../../assets/lsv_1.png')} />
        )}

        <h5 className="lg:mt-[21px] md:mt-[30px] text-Textlitecolor lg:text-[20px] text-[16px] font-medium">
          Download the Ridewise App{" "}
        </h5>
        <div className="flex xl:flex-row md:flex-col lg:gap-4 lg:mt-2 md:mt-5 mt-1 cursor-pointer">
          <Link to="https://apps.apple.com/pk/app/rentals-reimagined/id1672577637">
            <img
              className="lg:h-[50px] md:w-[140px] md:h-[50px] sm:w-[95px] sm:h-[32px]  "
              src={Appstore}
            />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.app.ridewiserentalsreimagined">
            <img
              className="lg:h-[50px] md:w-[140px] md:h-[50px] sm:w-[95px] sm:h-[32px]  "
              src={Googleplay}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidecard;
