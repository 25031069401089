import React, { useState, useEffect } from "react";
import { get, post } from "../../utils/axiosInstance";
import Spinner from "../Spinner";
import Searchcarttwo from "./Searchcarttwo";
import { useDispatch, useSelector } from "react-redux";
import { setCart, setExtraCart } from "../../state";

import Toast from "../Toast";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import LoadingButton from "../LoadingButton";
const Searchcartone = ({
  switchComponent,
  goToNextStep,
  goToPreviousStep,
  setCurrentStep,
}) => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.token);
  const assigned_cart = useSelector((state) => state.assigned_cart);
  const extraCart = useSelector((state) => state.extra_cart);
  const storedAddress = useSelector((state) => state.address);
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const user_selected_vendor_id = useSelector(
    (state) => state.user_selected_vendor_id
  );
  const [loader, setLoader] = useState(false);
  const [setsummaryData, setShowSummaryData] = useState([]);
  const [setData, setShowData] = useState({});
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const vendorId = selectedVendor?.id ?? user_selected_vendor_id;

  const selectedValue = useSelector((state) => state.selectedValue);

  const [visible, setVisible] = useState(false);
  const handleDelete = (itemId) => {
    const updatedCartData = extraCart.filter((item) => item.id !== itemId);
    dispatch(setExtraCart(updatedCartData));
  };

  const ResponseData = [
    {
      value: "Category",
      response: assigned_cart?.cart_type,
    },
    {
      value: "Color",
      response: assigned_cart?.color,
    },
    {
      value: "Make",
      response: assigned_cart?.make,
    },
    {
      value: "Model",
      response: assigned_cart?.model,
    },
    // {
    //   value: "License plate #",
    //   response: assigned_cart?.license_plate_number,
    // },
    {
      value: "Class",
      response: assigned_cart?.category ? assigned_cart?.category.name : "",
    },
    // {
    //   value: "Drive",
    //   response: assigned_cart?.drive,
    // },
  ];

  const renderData = () => {
    if (Array.isArray(ResponseData) && ResponseData.length > 0) {
      return ResponseData.map((cart, index) => (
        <div key={index}>
          <ul className="flex justify-between ">
            <li className="text-Inputtextcolor md:text-[16px] text-[12px] w-[50%]">
              {cart.value}
            </li>
            <li className="md:text-[16px] text-[12px] w-[50%] text-[#0E2740] capitalize">
              {cart.response}
            </li>
          </ul>
        </div>
      ));
    }
  };

  useEffect(() => {
    // assigned_cart == null && getCategories();
    if (extraCart.length !== 0) {
      setVisible(true);
    } else if (assigned_cart && assigned_cart !== null) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, []);

  const PostData = async () => {
    let daysDifference = JSON.parse(localStorage.getItem("daysDifference"));
    var formdata = new FormData();
    let start = await localStorage.getItem("startDate");
    let end = await localStorage.getItem("endDate");

    formdata.append("vendor_id", vendorId);
    formdata.append("days", daysDifference);
    formdata.append("address_id", storedAddress.id);
    formdata.append("start_date", moment(start, "MM/DD/YYYY").format("YYYY-MM-DD"));
    formdata.append("end_date", moment(end, "MM/DD/YYYY").format("YYYY-MM-DD"));

    setLoading(true);
    post("/booking/sync-data", formdata, {}, Token)
      .then((res) => {
        if ("response" in res) {
          setLoading(false);
          let summaryData = res.response.data;
          setShowSummaryData(summaryData);
          localStorage.setItem("summary_data", JSON.stringify(summaryData));
          console.log('====================================');
          console.log("summaryData", summaryData);
          console.log('====================================');
          const Data = localStorage.getItem("summary_data");
          console.log("SearchCartone summary_data", JSON.parse(Data));
          switchComponent("Summary");
          goToNextStep();
        } else {
          setLoading(false);
          Toast({
            message: `Unexpected response format:${res}`,
            isError: true,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        Toast({ message: err, isError: true });
      });
  };

  if (loader) {
    return (
      <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
        <Spinner className="spinner" isLoading={loader} />
      </div>
    );
  } else {
    return (
      <div className="bg-[#FFFFFF] rounded-[10px]  flex flex-col justify-between items-center  w-[100%]  md:h-[670px] h-[87vh]">
        <p className="md:my-[27px] my-[20px] md:text-[22px] text-[18px] font-bold text-Textdarkcolor w-[90%]">
          LSV Details & Description
        </p>
        {/* {visible ? ( */}
        <div className="lg:w-[60%] w-[95%] h-[100%] py-2 px-4 custom-scrollbar">
          <span
            className="text-Appcolor lg:w-[60%] md:w-[20%] w-[80%] ml-auto lg:text-[16px] text-[12px] font-bold flex flex-row-reverse    cursor-pointer"
            onClick={() => switchComponent("Form")}
          >
            Add Additional LSV?
          </span>
          {/* <div>
              {assigned_cart?.image !== "" || null ? (
                <img
                  className="lg:w-[250px] w-[200px] object-contain mx-auto"
                  src={assigned_cart?.image || noimage}
                  alt="Cart Image"
                />
              ) : (
                <div className="bg-white mt-[30px]  flex-col font-medium rounded-xl shadow-[10px_2px_30px_2px_#00000014] p-4 mx-auto w-[100%]">
                  <img className="w-[200px] mx-auto" src={noimage} />
                  <div className="flex flex-col text-gray-400 w-[100%]  py-4 justify-center items-center">
                    Image not available
                  </div>
                </div>
              )}
            </div> */}

          {assigned_cart ? (
            <div>
              <div className="bg-white mt-[20px]  flex-col font-medium rounded-xl shadow-[10px_2px_30px_2px_#00000014] p-4 mx-auto w-[100%]">
                <p className="text-red-500 xl:text-[16px] md:text-[14px]  text-[12px]">
                  Note: LSV might not be the same as shown in the picture.
                </p>
                <div className="flex flex-col pt-5 w-[100%] text-start">
                  {renderData()}
                </div>
              </div>
              <div className="bg-white mt-[20px] flex-col font-medium rounded-xl shadow-[10px_2px_30px_2px_#00000014] md:px-4 md:py-4 px-4 py-4 mx-auto w-[100%]">
                <div className="flex flex-col mt-1 w-[100%] text-start">
                  <div>
                    <p className="text-Textdarkcolor capitalize xl:text-[20px] md:p-2 p-[2px] md:text-[16px] text-[14px]">
                      {assigned_cart?.category?.name}
                    </p>
                    <ul className="flex items-center gap-1 pb-2">
                      <li className="bg-black rounded-full md:w-2 md:h-2 md:ml-3  w-[6px] h-[6px] ml-2"></li>
                      <li className="xl:text-[16px] md:pt-0 md:text-[14px] text-[12px] text-Textdarkcolor capitalize">
                        {assigned_cart?.category?.description}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center w-full lg:h-[400px] md:h-[360px] h-[290px]">
              <Spinner className="spinner" isLoading={loader} />
            </div>
          )}
          {extraCart && extraCart.length > 0 && (
            <div className=" w-full lg:my-10 my-5">
              <Searchcarttwo
                apiData={extraCart}
                onDelete={handleDelete}
                showDelete={true}
              />
            </div>
          )}
        </div>

        <div className="flex justify-between w-[90%] lg:my-10 my-5">
          <button
            className="bg-Btncolor text-Textdarkcolor lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[120px] h-[40px]"
            onClick={() => {
              dispatch(setExtraCart([]));
              dispatch(setCart({}));
              switchComponent("Searchaddress");
              goToPreviousStep();
              setCurrentStep(1);
            }}
          >
            Back
          </button>
          <LoadingButton
            isLoading={loading}
            onClick={() => PostData()}
            className="bg-Appcolor text-white lg:text-[16px] text-[12px] rounded-[10px] lg:w-min-[150px] w-full  lg:h-[50px] md:w-[130px] md:h-[40px] px-2 h-[35px]"
          >
            Book Now
          </LoadingButton>
          {/* <button
            className="bg-Appcolor cursor-pointer text-white lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[120px] h-[40px]"
            onClick={PostData}
          >
            Book Now
          </button> */}
        </div>
        <ToastContainer limit={1} />
      </div>
    );
  }
};

export default Searchcartone;
