import Booking from "./Home/Booking";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Sidecard from "./Home/Sidecard";
import Searchcartone from "./Home/Searchcartone";
import Searchcarttwo from "./Home/Searchcarttwo";
import Form from "./Home/Form";
import Summary from "./Home/Summary";
import Searchmap from "./Home/Searchmap";
import Vendors from "./Home/Vendors";
import Searchaddress from "./Home/Searchaddress";
import { useDispatch, useSelector } from "react-redux";
import VendorDetails from "./Home/VendorDetail";
import Spinner from "./Spinner";

import { patch } from "../utils/axiosInstance";
import { setLogin, setUseJourneyVendor, setUserState } from "../state";
import Searchmapform from "./Home/Searchmapform";
const Homecard = ({
  goToNextStep,
  goToPreviousStep,
  innerRef,
  setCurrentStep,
}) => {
  const useJourneyVendor = useSelector((state) => state.useJourneyVendor);
  const vandor = useSelector((state) => state.selectedVendor);
  const assigned_cart = useSelector((state) => state.assigned_cart); // Add this line
  const [removeBackButton, setRemoveBackButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false)
  const Token = useSelector((state) => state.token);
  const [activeComponent, setActiveComponent] = useState("Vendors");
  const user = useSelector((state) => state.user);
  const user_selected_vendor_id = user.user_selected_vendor_id;
  const dispatch = useDispatch();

  const updateStateSelectedVendor = () => {
    if (!isNaN(parseInt(user_selected_vendor_id))) {
      setLoading(true)
      patch(`/user/update`, { user_selected_vendor_id }, {}, Token)
        .then((res) => {

          const response = res.response.data.user;
          dispatch(
            setUserState({
              ...user,
              response,
            })
          );
          if (response.user_selected_vendor_id !== null) {
            setCurrentStep(1);
            setActiveComponent("Searchaddress");
          }
          setLoading(false)
        })
        .catch((profileErr) => {
          setLoading(false)
          console.log("profileErr", profileErr);
        });
    } else {
      setLoading(false)
      setCurrentStep(0);
      setActiveComponent("Vendors");
    }
  };

  const postSelectedVendorId = () => {
    setLoading(true)
    patch(
      `/user/update`,
      {
        user_selected_vendor_id: useJourneyVendor,
      },
      {},
      Token
    )
      .then((res) => {
        const updatedJourneyVendor = res.response.data.user.useJourneyVendor;
        dispatch(setUseJourneyVendor(updatedJourneyVendor));
        setLoading(false)
      })
      .catch((err) => {
        console.error("Error posting user selected vendor ID:", err);
        setLoading(false)
      });
  };

  const updateState = () => {
    if (useJourneyVendor && vandor !== null) {
      postSelectedVendorId();
      setCurrentStep(1);
      setActiveComponent("Searchaddress");
    } else {
      setCurrentStep(0);
      setActiveComponent("Vendors");
    }
  };

  useImperativeHandle(innerRef, () => ({
    updateState,
    updateStateSelectedVendor,
  }));

  useEffect(() => {
    if (useJourneyVendor && vandor !== null) {
      if (loader) {
        return (
          <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
            <Spinner className="spinner" isLoading={loader} />
          </div>
        );
      } else {
        setCurrentStep(1);
        setActiveComponent("Searchaddress");
      }
      setRemoveBackButton(true);
    }
  }, [useJourneyVendor, vandor]);

  useEffect(() => {
    if (!isNaN(parseInt(user_selected_vendor_id))) {
      if (user_selected_vendor_id === null) {
        setCurrentStep(0);
        setActiveComponent("Vendors");
        setRemoveBackButton(false);
      } else {
        setCurrentStep(1);
        setActiveComponent("Searchaddress");
        setRemoveBackButton(true);
      }
    }
  }, [user_selected_vendor_id]);

  const isFromSearchcart =
    activeComponent === "Searchcartone" ||
    activeComponent === "Form" ||
    activeComponent === "Summary";
  const OnVendor = activeComponent === "Vendors";

  const renderComponent = () => {
    switch (activeComponent) {
      case "Vendors":
        return (
          <Vendors
            OnVendor={OnVendor}
            switchComponent={setActiveComponent}
            goToNextStep={goToNextStep}
          />
        );
      case "VendorDetails":
        return (
          <VendorDetails
            switchComponent={setActiveComponent}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case "Searchaddress":
        return (
          <Searchaddress
            removeBackButton={removeBackButton}
            switchComponent={setActiveComponent}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case "Searchmap":
        return (
          <Searchmap
            setCurrentStep={setCurrentStep}
            switchComponent={setActiveComponent}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case "Searchmapform":
        return (
          <Searchmapform
            setCurrentStep={setCurrentStep}
            switchComponent={setActiveComponent}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case "Booking":
        return (
          <Booking
            setCurrentStep={setCurrentStep}
            switchComponent={setActiveComponent}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case "Searchcartone":
        return (
          <>
            <Searchcartone
              setCurrentStep={setCurrentStep}
              isFromSearchcart={isFromSearchcart}
              switchComponent={setActiveComponent}
              goToNextStep={goToNextStep}
              goToPreviousStep={goToPreviousStep}
            />
          </>
        );
      case "Form":
        return (
          <Form
            isFromSearchcart={isFromSearchcart}
            switchComponent={setActiveComponent}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case "Searchcarttwo":
        return (
          <Searchcarttwo
            switchComponent={setActiveComponent}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case "Summary":
        return (
          <Summary
            setCurrentStep={setCurrentStep}
            isFromSearchcart={isFromSearchcart}
            switchComponent={setActiveComponent}
            goToPreviousStep={goToPreviousStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-[100%] mt-5 md:py-8 pb-8 flex justify-center h-[100%] bg-Btncolor">
      <div className="md:w-[30%] hidden lg:block">
        {isFromSearchcart ? (
          <Sidecard
            isFromSearchcart={isFromSearchcart}
            imageData={assigned_cart?.image}
            OnVendor={OnVendor}
            loading={loading}
          />
        ) : (
          <Sidecard OnVendor={OnVendor} loading={loading} />
        )}
      </div>
      <div className="lg:w-[60%] w-[90%] flex flex-col">
        {renderComponent()}
      </div>
    </div>
  );
};

export default forwardRef(Homecard);
