import React, { useState, useEffect } from "react";
import { get, patch, post } from "../../utils/axiosInstance";
import Logo from "../../assets/Logo.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  setBookedDates,
  setLogin,
  setSelectedVendor,
  setUseJourneyVendor,
} from "../../state";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StarRatings from "react-star-ratings";
import { Confirmationmodal } from "../Modals/Confirmationmodal";
import { useNavigate } from "react-router-dom";
import Tick from "../../assets/tick.svg";

import DefaultVendorDetails from '../Settings/DefaultVendorDetails'

const DefaultVendor = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const Token = useSelector((state) => state.token);
  const [apiData, setApiData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const [abc, setAbc] = useState({ label: "", item: null });

  const useJourneyVendor = useSelector((state) => state.useJourneyVendor);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    get(`/vendor`, {}, {}, Token)
      .then((res) => {
        if ("response" in res) {
          const response = res.response?.data?.vendors;

          dispatch(setBookedDates([]));
          setApiData(response);
        }
      })
      .catch((err) => {
        console.error("Error fetching vendors:", err);
      });
  }, []);



  // console.log("selectedVendor",selectedVendor);

  const handleButtonClick = (label, item) => {
    if (
      user?.user_insurance?.status === "rejected" &&
      user?.user_license?.status === "rejected"
    ) {
      toast("License Rejected", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        type: "error",
      });
    } else {
      dispatch(setSelectedVendor(item));
    }
  };



  const postSelectedVendorId = (item) => {
    patch(
      `/user/update`,
      {
        user_selected_vendor_id: item,
        vendor_id: item,
      },
      {},
      Token
    )
      .then((res) => {
        // console.log("res=====>",res.response.data.user.default_vendor);

        dispatch(
          setLogin({
            user: res.response.data.user,
            token: res.response.data.user?.token,
            user_type: res.response.data.user?.user_type,
            app_vendor_id: res.response.data.user?.app_vendor_id,
            user_selected_vendor_id: res.response.data.user.default_vendor.id,

          })
        );

        dispatch(setSelectedVendor(res.response.data.user.default_vendor));
        dispatch(setUseJourneyVendor(res.response.data.user.default_vendor.id));
      })

      .catch((err) => {
        console.error("Error posting user selected vendor ID:", err);
      });
  };

  const handleYesButtonClick = () => {
    //
    dispatch(setSelectedVendor(null));
    //
    postSelectedVendorId(abc?.item?.user?.vendor_id);
    handleButtonClick(abc?.item);
    dispatch(setSelectedVendor(abc?.item));
    setShowModal(false);
  };

  const handleNoButtonClick = () => {
    dispatch(setSelectedVendor(abc?.item));
    setShowModal(false);
  };



  const [showDetails, setShowDetails] = useState(false);

  const handleDetailsButtonClick = () => {
    setShowDetails(true);
  };

  const handleBackButtonClick = () => {
    setShowDetails(false);
  };

  return (
    <div>
      {!showDetails ? (
        <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col   w-[100%] md:h-[670px]   p-4">
          <div className="custom-scrollbar w-full h-full">

            <div className="grid xl:grid-cols-3 lg:place-items-center lg:grid-cols-2 grid-col-2 gap-y-4 gap-x-4">
              {apiData &&
                Array.isArray(apiData) &&
                apiData.map((item, ind) => {
                  return (
                    <div
                      className="mt-2 border-[#E2E2E2] border-[1px]  rounded-t-[20px] rounded-b-[20px] rounded-l-[20px] rounded-r-[20px]"
                      key={ind}
                    >
                      <div
                        onClick={() => {
                          setShowModal(true);
                          setAbc({
                            label: "Searchaddress",
                            item: item,
                          });

                        }}
                        className={`flex flex-col shadow-[7px_5px_-18px_#E2E2E2] 2xl:w-[240px] lg:w-[200px] md:w-[350px] sm:w-[300px]  h-[270px] cursor-pointer m-2 relative group`}
                      >

                        <div className="bg-white w-full h-[130px] flex justify-center items-center rounded-md">
                          <img
                            src={item?.logo || require('../../assets/Png/placeholderImg.jpeg')}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = require('../../assets/Png/placeholderImg.jpeg');
                            }}
                            className="w-[90%] max-h-[130px] flex justify-center items-center rounded-md"
                          />
                        </div>
                        {user && user?.default_vendor && user?.default_vendor?.id === item.id && (
                          <span className="absolute -top-2 -right-2  bg-Appcolor rounded-2xl w-[30px] h-[30px]">
                            <img src={Tick} alt="Tick" className="absolute  top-0 right-0  " />
                          </span>
                        )}

                        <div className="absolute bottom-10  left-0 right-0 p-4  text-center">
                          <p
                            className={` text-black lg:text-[21px] font-bold text-[15px]`}
                          >
                            {item?.title}
                          </p>
                          <p
                            className={`text-black line-clamp-2 font-semibold text-center 2xl:text-[16px] w-[100%] xl:text-[14px] text-[12px]`}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.description ?? "",
                              }}
                            ></div>
                          </p>
                        </div>

                        <div className="absolute flex px-2 justify-center pb-4 w-full bottom-0 items-center">
                          <div>
                            <StarRatings
                              rating={item.average_ratings}
                              isSelectable={false}
                              starDimension="20px"
                              starSpacing={"-1px"}
                              numberOfStars={1}
                              isAggregateRating={true}
                              starRatedColor="rgb(255, 193, 7)"
                            />
                          </div>

                          <p className="text-Textdarkcolor text-[16px] ml-1">
                            {item.average_ratings?.toFixed(1)}/
                          </p>
                          <p className="text-Textdarkcolor text-[16px] ">5</p>
                        </div>
                      </div>
                      <button
                        className={`button -mt-3 w-[100%] flex justify-center p-3 
              rounded-[10px 40px] shadow-[0_5px_40px_-20px_#0075FF] text-white font-normal lg:text-[16px] text-[12px]`}
                        onClick={() => {
                          setAbc({
                            label: "DefaultVendorDetails",
                            item: item,
                          });
                          handleDetailsButtonClick()
                        }}


                      >
                        Vendor Details{" "}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>

          <Confirmationmodal
            showModal={showModal}
            setShowModal={setShowModal}
            selectedVendorId={selectedVendorId}
            onYesButtonClick={handleYesButtonClick}
            onNoButtonClick={handleNoButtonClick}
            name={abc?.item?.title}
          />
          <ToastContainer limit={1} />
        </div>

      ) : (
        <DefaultVendorDetails vendor={abc?.item} onBack={handleBackButtonClick} />
      )}

    </div>

  );
};

export default DefaultVendor;
