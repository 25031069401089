import React from "react";
import Inputfield from "../../components/Inputfiled";
import Button from "../../components/Button";
import Forgetpass from "../../assets/forgetpass.svg";
import { Forgotvalidationschema } from "../../utils/schema";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.svg";
import { post } from "../../utils/axiosInstance";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../Toast";

const ForgotPassword = () => {

  // const showErrorToastMessage = () => {
  //   toast.error("Not Send The Email Properly", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // };

  // const showSuccessToastMessage = () => {
  //   toast.success("Your Email Successfully Sent", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // };

  const handleSubmit = (e) => {
    post("/forgot-password", e)
      .then((res) => {
        if ("response" in res) {

          Toast({ message: "Your Email Successfully Sent", isError: false });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      })
      .catch((err) => {
        // showErrorToastMessage()
        // Toast({ message: "Not Send The Email Properly", isError: true });
        Toast({ message: err?.response?.data?.error?.messages[0], isError: true });

        // alert("Not Send");
      });
  };

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Forgotvalidationschema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className=" w-full ">
      <div className="w-[50%] md:block hidden bg-Appcolor h-full  fixed  ">
        <div className=" flex flex-col mt-[120px]  ">
          <div className="flex w-[100%] pb-10 justify-center">
            <p className="text-[36px] font-bold w-[50%] text-center text-white">
              Forgot Password
            </p>
          </div>

          <img
            src={Forgetpass}
            className=" w-full h-[478px] pt-24 "
            alt="Logo"
          />
        </div>
      </div>
      <img
        src={Logo}
        className="block md:hidden mx-auto h-[150px] w-[280px]"
        alt="Logo"
      />
      <div className="md:w-[50%] w-[100%]  md:h-[100vh] flex justify-center items-center  ml-auto lg:w-6/12 xl:w-6/12 ">
        <form
          className="w-[80%] mx-auto text-center"
          onSubmit={formik.handleSubmit}
        >
          <h1 className="heading  ">Forgot Password</h1>
          <h2 className="flex justify-center lg:text-[18px]  lg:mt-[50px] mt-[25px] font-medium text-Textdarkcolor">
            Forgot Password?
          </h2>
          <p className="flex justify-center text-[18px] font-medium text-Textlitecolor">
            Enter the email address associated with your account
          </p>
          <div className="flex flex-col justify-start md:mt-[125px] mt-[30px]">
            <Inputfield
              labelstyle="flex text-[18px]   text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
              Labelname="Email"
              type="email"
              placeholder="Enter your Email"
              name="email"
              htmlFor="email"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              errors={formik.errors.email}
              touched={formik.touched.email}
            />
          </div>

          <div className="lg:gap-5 gap-3 flex lg:justify-between lg:flex-row flex-col">
            <div
              onClick={() => navigate("/")}
              className="lg:w-[50%] w-full  md:mt-[60px] mt-[30px] shadow-[0_5px_40px_-20px_#0075FF] bg-Appcolor rounded-lg text-white font-medium lg:p-6 p-3 text-[18px]"
            >
              <Button divstyle="w-full button " btnname="Go Back" />
            </div>
            <div className="lg:w-[50%] w-full  lg:mt-[60px] mt-[5px] shadow-[0_5px_40px_-20px_#0075FF] text-white font-medium lg:p-6 p-3 text-[18px]  bg-Appcolor rounded-lg ">
              <Button divstyle=" w-full button " btnname="Send" type="submit" />
            </div>
          </div>

          <div className="w-full text-Textdarkcolor text-center md:mt-[60px] mt-[20px]">
            <p>We will email you a link to reset your password</p>
          </div>
        </form>
      </div>
      <ToastContainer limit={1} />
    </div>
  );
};

export default ForgotPassword;