import React, { useState, useEffect } from "react";
import Appstore from "../../assets/Png/dummy_email.png";
export function PopupmodalEmail({ showModal, setShowModal }) {
  if (!showModal) return null;
  return (
    <div
      id="popup-modal"
      tabindex="-1"
      class={`${showModal ? "flex" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
      <div class="relative p-4 w-full max-w-md max-h-full ">
        <div class="relative bg-white rounded-lg shadow-xl shadow-gray-500 items-center justify-center center">

          <div class="p-4 md:p-5 text-center ">
            <img
              className="h-40 w-full object-contain"
              src={Appstore}
            />
            <h3 class="mb-5 text-lg font-bold text-black dark:text-black">Please verify your email to continue.</h3>
            <button onClick={() => setShowModal(false)} data-modal-hide="popup-modal" type="button" class="text-white bg-Appcolor hover:bg-Appcolor focus:ring-4 focus:outline-none focus:bg-Appcolor dark:focus:bg-Appcolor font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupmodalEmail;
